<template>
<mdb-footer color="stylish-color-dark" class="font-small mt-4 text-center py-3">
    <a href="https://www.ujaen.es/aviso-legal">Aviso legal</a>  &#65372;  <a href="https://www.ujaen.es/politica-de-privacidad">Política de privacidad</a>
</mdb-footer>
</template>

<script>
import { mdbFooter } from 'mdbvue';
export default {
    name: 'footermb',
    components: {mdbFooter }
}
</script>